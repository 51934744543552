<template>
  <div class="auth-layout-wrap">
    <div class="auth-content">
      <div class="card o-hidden">
        <div class="row">
          <div class="col-md-6">
            <div class="p-4">
              <v-row justify="center" align="center" v-if="register">
                <v-col cols="12" sm="12">
                  <v-card elevation="0" class="mx-auto">
                    <div class="auth-logo text-center mt-10">
                      <img :src="logo" style="width: 100px" />
                    </div>
                    <h1 class="text-center">Register</h1>
                    <h5 class="text-center pl-2">
                      You have to be an active member of MNP to register an
                      account
                    </h5>
                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                          v-model="id_card_no"
                          :rules="idCardRules"
                          label="ID Card No"
                          :disabled="otp_sent"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="mobile_no"
                          :rules="mobileRules"
                          :disabled="otp_sent"
                          label="Mobile No"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="otp"
                          :rules="otpRules"
                          :disabled="otp_verified"
                          v-if="otp_sent"
                          label="OTP"
                          required
                        ></v-text-field>
                        <template v-if="otp_verified">
                          <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="E-mail"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="password"
                            :rules="passwordRules"
                            label="Password"
                            type="password"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="confirm_password"
                            :rules="confirmPasswordRules"
                            label="Confirm Password"
                            type="password"
                            required
                          ></v-text-field>
                        </template>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-row class="pa-4">
                        <v-btn
                          depressed
                          color="warning"
                          @click="register = false"
                          class="pl"
                        >
                          LOGIN
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          depressed
                          color="success"
                          v-if="!otp_verified && !otp_sent"
                          @click="verifyMember"
                          class="pr"
                        >
                          Verify Member
                        </v-btn>
                        <v-btn
                          depressed
                          color="success"
                          v-if="!otp_verified && otp_sent"
                          @click="verifyOTP"
                          class="pr"
                        >
                          Verify OTP
                        </v-btn>
                        <v-btn
                          depressed
                          color="success"
                          v-if="otp_verified && otp_sent"
                          @click="createAccount"
                          class="pr"
                        >
                          Create Account
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row justify="center" align="center" v-if="!register">
                <v-col cols="12" sm="12">
                  <v-card elevation="0" class="mx-auto">
                    <div class="auth-logo text-center mt-10">
                      <img :src="logo" style="width: 100px" />
                    </div>
                    <h1 class="text-center">Sign In</h1>
                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="E-mail"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="password"
                          :rules="passwordRules"
                          label="Password"
                          type="password"
                          required
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-row class="pa-4">
                        <v-btn
                          depressed
                          color="warning"
                          @click="
                            register = true;
                            otp_sent = false;
                            otp_verified = false;
                          "
                          class="pl"
                        >
                          CREATE ACCOUNT
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          depressed
                          color="success"
                          @click="signIn"
                          class="pr"
                        >
                          Login
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <v-overlay absolute :value="loading" :opacity="0.7">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </div>
          <div class="col-md-6">
            <img :src="bg" style="width: 100%" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      valid: true,
      loading: false,
      register: false,
      otp_verified: false,
      otp_sent: false,
      id_card_no: "",
      idCardRules: [
        (v) => !!v || "ID Card is required",
        (v) => (v && v.length == 7) || "ID Card Number is 7 characters",
      ],
      mobile_no: "",
      mobileRules: [
        (v) => !!v || "Mobile Number is required",
        (v) => (v && v.length == 7) || "Mobile Number is 7 characters",
        (v) => (v && v >= 7000000 && v <= 9999999) || "Invalid Mobile Number",
      ],
      otp: "",
      otpRules: [
        (v) => !!v || "OTP is required",
        (v) => (v && v.length == 6) || "OTP is 6 characters",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      confirm_password: "",
      confirmPasswordRules: [
        (v) => !!v || "Confirmation Password is required",
        (v) =>
          (v && v == this.password) ||
          "Password and Confirmation Password has to be same",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      logo: require("@/assets/images/logo.png"),
      bg: require("@/assets/images/bg.jpg"),
    };
  },
  methods: {
    signIn() {
      this.$refs.form.validate();
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
      };
      this.api("auth/login", f)
        .then((response) => {
          this.loading = false;
          console.log(response);
          if (response.status == true) {
            localStorage.setItem(
              "mnp_member_user",
              JSON.stringify(response.user)
            );
            localStorage.setItem("mnp_member_token", response.token);
            localStorage.setItem(
              "mnp_member_menu",
              JSON.stringify(response.menu)
            );
            localStorage.setItem(
              "mnp_member_campaigns",
              JSON.stringify(response.campaigns)
            );
            document.location.href = "/";
          } else {
            this.handleError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verifyMember() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
        id_card_no: this.id_card_no,
        mobile_no: this.mobile_no,
      };
      this.api("auth/verify-member", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.otp_sent = true;
            this.showInfo("OTP sent to your Mobile");
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmOtpResend() {
      Swal.fire({
        title: "OTP Expired",
        text: "Do you want to resend a new OTP?",
        type: "question",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#5daf34",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonText: "YES, SEND NEW OTP",
      }).then((result) => {
        if (result.value) {
          this.verifyMember();
        }
      });
    },
    verifyOTP() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
        id_card_no: this.id_card_no,
        mobile_no: this.mobile_no,
        otp: this.otp,
      };
      this.api("auth/verify-otp", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.otp_verified = true;
            this.showInfo("Enter the Email Address and Password");
          } else if (response.error == "OTP Expired.") {
            this.confirmOtpResend();
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createAccount() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
        confirm_password: this.confirm_password,
        id_card_no: this.id_card_no,
        mobile_no: this.mobile_no,
        otp: this.otp,
      };
      this.api("auth/create-account", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.register = false;
            this.showInfo(
              "Check your email and Verify Email before you can login"
            );
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "SignIn",
};
</script>
<style scoped>
.o-hidden {
  overflow: hidden;
}
.card {
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
  border: 0;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
@media (min-width: 1024px) .auth-layout-wrap .auth-content {
  min-width: 660px;
}
.auth-layout-wrap .auth-content {
  max-width: 660px;
  margin: auto;
}
.auth-layout-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
}
</style>
